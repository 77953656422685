<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Les Frenchies
          </h2>
        </b-link>
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t("pages.login.welcome_page") }} 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("pages.login.please_sign_in") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  :disabled="isLoading"
                  @click="validationForm"
                >
                  {{ $t("pages.login.sign_in") }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import commonServices from '@/services/commonServices';
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { Ripple },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.onSubmit();
        }
      });
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('auth/signIn', {
          context: this,
          email: this.userEmail,
          password: this.password,
          next: '/',
        });
      } catch {
        commonServices.showError(this, 'msg.error.error');
      } finally {
        this.isLoading = false;
      }
    },
    navRreset() {
      this.$router.push('/forgot-password');
    },
    getMessage() {
      return this.message;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
